import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import React, { useEffect } from 'react';
import CustomWalletConnect from '@/components/UI/CustomWalletConnect';
import { ButtonType } from '@/components/UI/buttons/Button';

const Login = () => {
  const router = useRouter();
  const shouldReturnParam = router.query.shouldReturn;
  const shouldReturn = shouldReturnParam === 'true';
  const returnToParam = router.query.returnTo;

  const { data: session } = useSession();

  useEffect(() => {
    if (session) {
      if (shouldReturn) {
        returnToParam ? router.replace(returnToParam as string) : router.back();
      } else {
        void router.push('/');
      }
    }
  }, [router, session, shouldReturn, returnToParam]);

  return (
    <div className="relative mb-20 mt-[115px] flex h-full w-full items-center justify-center px-8 md:mt-[183px]">
      <div className="container relative flex max-w-[736px] flex-col rounded-3xl bg-primary-surface px-7 pb-4 pt-20 text-center md:px-24 md:pb-7 md:pt-24">
        <p className="text-heading-4-mobile font-bold text-black md:text-heading-4">
          Oops! You need to be logged in before you can proceed.
        </p>
        <p className="text-small pt-4 text-black">
          If you already have an account with OverpassIP, please log in. If you
          don’t have an account yet, you can easily create one by clicking on
          the “Connect Wallet” or “Email Login” buttons.
        </p>
        <div className="flex w-full items-center justify-center pt-6 font-bold text-black">
          <CustomWalletConnect buttonClass={`${ButtonType.light} px-4`} />
        </div>
        <div
          className="
            absolute left-0 right-0
            m-auto -mt-[130px] h-[100px] w-[100px]
            justify-center
            md:-mt-[156px] md:h-[120px] md:w-[120px]
          "
        >
          <Image
            src={'/img/Checkbox.png'}
            height="100%"
            width="100%"
            layout="fill"
            alt="Error Icon"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
